<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>医生设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :inline="true" style="text-align: center">
            <el-form-item label="院区">
                <select-hospital v-model="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item label="科室">
                <select-department v-model="search.department_id" :hospital_id="search.hospital_id" size="small"/>
            </el-form-item>
            <el-form-item>
                <el-input v-model="search.keywords" size="small" placeholder="关键词" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain size="small" @click="handleSearch">检索</el-button>
            </el-form-item>
        </el-form>
        <div style="margin-bottom:10px">
            <el-button type="primary" size="small" @click="handleAdd">添加医生</el-button>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;display: flex">
                            <div><img :src="props.row.avatar" style="width: 100px" /></div>
                            <div style="padding:0 20px;">简介：{{props.row.summary}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="small" type="warning" icon="el-icon-edit"></el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDel(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
        <el-dialog title="设置" :visible.sync="dialogVisible" width="40%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="院区">
                    <select-hospital v-model="form.hospital_id" size="small"/>
                </el-form-item>
                <el-form-item label="科室">
                    <select-department v-model="form.department_id" :hospital_id="form.hospital_id" size="small"/>
                </el-form-item>
                <el-form-item label="医生姓名">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="职称">
                    <el-input v-model="form.title" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="照片">
                    <el-upload
                        class="avatar-uploader"
                        action="/api/upload/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="form.avatar" :src="form.avatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model="form.summary" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="顺序">
                    <el-input-number v-model="form.sort" style="width:80%"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    import SelectDepartment from "@/components/form/selectDepartment";
    export default {
        components: {SelectDepartment},
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: ''},
                        {title: '科室', field: 'department.name', width: ''},
                        {title: '医生姓名', field: 'name', width: ''},
                        {title: '职称', field: 'title', width: ''},
                        {title: '排序', field: 'sort', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                search: {},
                dialogVisible: false,
                form: {
                    hospital_id: null,
                    department_id: null,
                    name: '',
                    title: '',
                    avatar: '',
                    summary: '',
                    sort: 0,
                },
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            handleAvatarSuccess(res, file) {
                // console.log(res)
                // this.form.avatar = URL.createObjectURL(file.raw);
                this.form.avatar = res
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';

                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {hospital_id: null, department_id: null, name: '', title: '', avatar:'', summary: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async handleDel(index, row){
                this.$refs[`popover-${index}`].doClose()
                const resp = await this.$http.delete(`/mingde/doctor/${row.id}`)
                if (resp.data.code == 200)
                    this.$message.success("删除成功")
                else
                    this.$message.error(resp.data.msg)
                this.getData()
            },
            async addSubmit() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/doctor/${this.form.id}` : `/mingde/doctor/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData() {
                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                const resp = await this.$http.get('/mingde/doctor/', {params:params})
                this.table.data = resp.data.data.data || []
                this.table.total = resp.data.data.total
            },
            handleSearch() {
                this.table.page = 1
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }
    .avatar {
        width: 200px;
    }

</style>
